<template>
    <app-wrapper>
        <div class="user-info">
            <v-container>
                <v-row>
                    <v-col class="d-flex flex-column align-items-center">
                        <v-avatar color="grey lighten-1" class="my-3 user-avatar" size="85">
                            <v-img
                                :src="currentUser.avatar"
                                :lazy-src="currentUser.avatar"
                            ></v-img>
                        </v-avatar>
                        <strong><span>@</span>{{ currentUser.user_name }}</strong>
                    </v-col>
                </v-row>
            </v-container>
            <div class="user-stats">
                <v-container>
                    <v-row>
                        <v-col v-for="(stat,index) in userStats" :key="index" class="text-center">
                            <div>
                                <v-icon small class="align-baseline mr-1">{{ stat.icon }}</v-icon>
                                <strong class="stat-number">{{ stat.value }}</strong>
                            </div>
                            <div><strong>{{ stat.label }}</strong></div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <div class="user-menu">
            <app-container>
                <div class="d-flex flex-column">
                    <v-btn 
                        v-for="(item, index) in menuItems" 
                        :key="index" 
                        :href="item.href ? item.href : '#'"
                        target="_blank"
                        x-large
                        class="mb-3 user-menu-item text-left"
                        @click="itemClickHandle(item, $event)"
                    >{{ item.label }}</v-btn>
                </div>
            </app-container>
        </div>
    </app-wrapper>
</template>
<script>

import AppContainer from "./layouts/AppContainer.vue";
import AppData from '../AppData';
import AppWrapper from './layouts/AppWrapper.vue';

import { mdiAccountGroup, mdiAccountMultipleCheck, mdiHeart } from '@mdi/js';
export default {
    data() {
        return {
            icons: {
                mdiAccountGroup, mdiAccountMultipleCheck, mdiHeart,
            },
        };
    },
    methods: {
        itemClickHandle(item,e){
            if(item.clickEvent){
                e.preventDefault();
                item.clickEvent();
            }
        },
        signOut() {
            AppData.commit('logout');
        },
    },
    computed: {
        userStats() {
            return [
                { label: 'Followers', value: this.currentUser.follower_count, icon: this.icons.mdiAccountGroup }, 
                { label: 'Following', value: this.currentUser.following_count, icon: this.icons.mdiAccountMultipleCheck }, 
                { label: 'Hearts', value: this.currentUser.like_count, icon: this.icons.mdiHeart }, 
            ];
        },
        menuItems() {
            return [
                { label: 'Share App', href: '#' },
                { label: 'Contact Us', href: 'mailto:admin@apprankings.net' },
                { label: 'Policy and Terms', href: '#' },
                { label: 'Logout', clickEvent: this.signOut },
            ];
        }
    },
    components: { AppContainer, AppWrapper }
}
</script>

<style>
    .user-info{
        background: url('~@/assets/bg.jpg');
        background-size: cover;
        padding-top: 30px;
    }

    .user-info .user-avatar{
        border: 2px solid #fff;
    }

    .stat-number{
        font-size: 18px;
    }

    .user-stats{
        background-color: #212121e0;
        margin-top: 30px;
    }

    a.user-menu-item{
        text-transform: none;
        font-size: 17px;
    }

    a.user-menu-item .v-btn__content{
        justify-content: flex-start;
    }

    a.user-menu-item:hover{
        text-decoration: none;
    }
</style>