<template>
	<app-wrapper>

		<app-container>
			
			<v-scroll-x-transition hide-on-leave>
				<list-hearts
					v-if="!selectedPackage"
					:packages="packages"
					@alert-error="alertError"
					@select-package="selectPackage"
				></list-hearts>

				<div v-else>

					<!-- show selected package -->
					<list-hearts
						:packages="[this.selectedPackage]"
					></list-hearts>

					<!-- place video url -->
					<v-text-field
						ref="videoUrl"
						v-model="videoInput.value"
						label="Video URL"
						type="url"
						filled
						:rules="[videoInput.rules.required, videoInput.rules.url]"
						:loading="this.videoInput.loading"
						:disabled="this.videoInput.loading || this.isLoading"
						:error-messages="videoInput.error"
						persistent-placeholder
						placeholder="Place your Tiktok Video URL"
						@change="submitVideoURL"
					></v-text-field>

					<!-- show selected video -->
					<v-slide-y-reverse-transition>
						<v-row justify="center" align-content="center" v-if="this.selectedVideo">
							<v-col cols="8">
								<video-card :video="this.selectedVideo"></video-card>
							</v-col>
						</v-row>
					</v-slide-y-reverse-transition>

					<!-- submit buy hearts -->
					<div class="text-center my-8">
						<v-btn
							depressed
							color="primary"
							rounded
							dark
							@click="resetSelectedPackage"
							:disabled="this.videoInput.loading || this.isLoading"
							class="mx-3"
						>
							<v-icon left>{{ icons.mdiArrowLeft }}</v-icon>
							Back
						</v-btn>

						<v-btn
							depressed
							color="primary"
							rounded
							dark
							@click="submitBuyVideoHearts"
							:disabled="!selectedVideo || this.videoInput.loading || this.isLoading"
							class="mx-3"
						>
							<v-icon left>{{ icons.mdiHeartPlus }}</v-icon>
							Buy Hearts
						</v-btn>
					</div>
				</div>
			</v-scroll-x-transition>

		</app-container>

		<v-overlay :value="isLoading || !packages">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</app-wrapper>
</template>

<script>
import AppWrapper from "./layouts/AppWrapper.vue";
import AppContainer from "./layouts/AppContainer.vue";
import ListHearts from "./../components/ListHearts.vue";

import { mdiHeartPlus, mdiArrowLeft } from "@mdi/js";
import axios from "axios";
import AppData from '../AppData';
import VideoCard from './../components/VideoCard.vue';

export default {
	data() {
		return {
			isLoading: false,
			selectedVideo: null,
			selectedPackage: null,
			videoInput: {
				loading: false,
				error: '',
				value: null,
				rules: {
					required: value => !!value || 'Required.',
					url: value => {
						const pattern = /^https:\/\/www.tiktok.com\/@([\w.]+)\/video\/(\d+)/;
						return pattern.test(value) || 'Invalid Tiktok Video URL'
					},
				},
			},
            icons: {
				mdiHeartPlus, mdiArrowLeft
			},
		};
	},
	computed: {
		packages() {
			return AppData.getters.getHeartPackages;
		},
	},
	methods: {
		alertError(message) {
			this.$emit("alert-error", message);
		},
		alertSuccess(message) {
			this.$emit("alert-success", message);
		},
		selectPackage(_package) {
			if (this.currentUser.user_coin < _package.coins) {
				this.$emit('alert-insufficient');
			} else {
				this.selectedPackage = _package;
				setTimeout(() => {
					if(this.$refs['videoUrl']){
						this.$refs['videoUrl'].focus();
					}
				}, 200)
			}
		},
        submitVideoURL(){
            if(this.$refs['videoUrl'].validate(true)){
                this.videoInput.loading = true;
                let user = AppData.getters.getUser;
    
                axios.get(`webapp/video_info`, {
					params: {
						user_name: user.user_name,
						video_url: this.videoInput.value,
					},
				})
                .then((response) => {
                    if(!response.data.error && response.data.data.video){
                        this.selectedVideo = response.data.data.video;
                    }else{
                        this.videoInput.error = response.data.error;
                    }
                }).catch((err) => {
                    this.videoInput.error = err.message;
                }).finally(() => {
                    this.videoInput.loading = false;
                });
            }else{
				this.selectedVideo = null;
			}
		},
		submitBuyVideoHearts() {

			this.isLoading = true;

			axios.post('webapp/buy_hearts', {
				user_name: this.currentUser.user_name,
				package_id: this.selectedPackage.id,
				video_url: this.videoInput.value,
			}).then((response) => {
				if(!response.data.error && response.data.data.user){
					this.setCurrentUser(response.data.data.user);
					this.alertSuccess('Purchase successfully!');
					this.resetSelectedPackage();
				}else{
					this.alertError(response.data.error);
				}
			}).catch((err) => {
				this.alertError(err.message);
			}).finally(() => {
				this.isLoading = false;
			});
			
		},
		resetSelectedPackage() {
			this.selectedVideo = null;
			this.selectedPackage = null;
			this.videoInput.value = null;
		},
	},
	components: { AppWrapper, AppContainer, ListHearts, VideoCard },
};
</script>
