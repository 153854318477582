<template>
	<v-row>
		<v-col>
			<v-progress-circular
				v-if="paypalLoading"
				indeterminate
			></v-progress-circular>
			<div id="paypal-payment"></div>
		</v-col>
	</v-row>
</template>

<script>
import { loadScript as loadPaypalScript } from "@paypal/paypal-js";
import axios from "axios";

export default {
	props: {
		order: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			paypalLoading: true,
		};
	},
	methods: {
		async completePayment(paypalOrder) {
			this.$emit("reset-sheet");
			this.$emit("setLoading", true);

			return await axios
				.post("webapp/payment/paypal", {
					user_name: this.getCurrentUsername(),
					package_id: this.order.package.id,
					token: paypalOrder.id,
				})
				.then((resp) => {
					if (!resp.data.error) {
						if (resp.data.data.user) {
							this.setCurrentUser(resp.data.data.user);
						}
						setTimeout(() => {
							this.$emit(
								"alert-success",
								`Purchased ${this.order.package.coin} coins package successfully!`
							);
						}, 1000);
					} else {
						throw resp.data.error;
					}
				})
				.catch((err) => {
					this.$emit("alert-error", `[ERROR] ${err}`);
				})
				.finally(() => {
					this.$emit("setLoading", false);
				});
		},
	},
	mounted() {
		const clientID =
			// sandbox
			// "Af43cChE2DQgaUsEW1wVrIhs9Gq_muneErUHrhxlmBTm0jBtlwX4dCdmTwvH0PHLbAjNVN_bSsM4hkN_";
			// production
			"AfRbaEWF5-5kDDN2B5_R_AHRuONuZUy7nh0pNzB_5f9iQ2rmS4gMcMdLEFEfOFQsBbWzmhFyeoMD8-_W";

		loadPaypalScript({
			"client-id": clientID,
			currency: "USD",
		})
			.then((paypal) => {
				paypal
					.Buttons({
						createOrder: (data, actions) => {
							// Set up the transaction
							return actions.order.create({
								purchase_units: [
									{
										amount: {
											value: this.order.amount,
										},
									},
								],
								application_context: {
									shipping_preference: "NO_SHIPPING",
								},
							});
						},
						onApprove: (data, actions) => {
							return actions.order.capture().then((paypalOrder) => {
								return this.completePayment(paypalOrder);
							});
						},
						onCancel: () => {
							this.$emit("reset-sheet");
							this.$emit(
								"alert-error",
								"Payment has been canceled"
							);
						},
						onError: (err) => {
							this.$emit("reset-sheet");
							this.$emit("alert-error", err);
						},
					})
					.render("#paypal-payment");
			})
			.catch((err) => {
				console.error("failed to load the PayPal JS SDK script", err);
			})
			.finally(() => {
				this.paypalLoading = false;
			});
	},
};
</script>

<style>
#paypal-payment {
	max-height: 390px;
	overflow-y: scroll;
}
</style>