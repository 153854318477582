<template>
	<v-app>
		<v-main>
			<v-container fill-height fluid>
				<v-row>
					<v-col align="center" justify="center">
						<v-card
							class="p-5"
							elevation="2"
							max-width="600"
							rounded="xl"
						>
							<!-- logo -->
							<v-img
								class="mb-16 rounded-xl"
								lazy-src="@/assets/logo.png"
								max-height="150"
								max-width="150"
								src="@/assets/logo.png"
							></v-img>

							<!-- input -->
							<v-text-field
								class="my-5"
								:prepend-icon="icons.mdiAt"
								label="Username"
								placeholder="Tiktok Username"
								persistent-placeholder
								v-model="userName"
								:disabled="isLoading"
							>
							</v-text-field>

							<!-- submit btn -->
							<v-btn
								color="primary"
								elevation="4"
								large
								rounded
								:loading="isLoading"
								@click="login"
								>Sign In</v-btn
							>
						</v-card>

						<v-snackbar v-model="snackbar.enable" :timeout="2000" :color="snackbar.color">
							{{ snackbar.text }}

							<template v-slot:action="{ attrs }">
								<v-btn
									color="pink"
									text
									v-bind="attrs"
									@click="snackbar.enable = false"
								>
									Close
								</v-btn>
							</template>
						</v-snackbar>
						
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>

	import axios from 'axios';
	import AppData from './AppData';

	import { mdiAt } from '@mdi/js';

	export default {
		data() {
			return {
				userName: '',
				isLoading: false,
				snackbar: {
					enable: false,
					text: '',
					color: '',
				},
				icons: {
					mdiAt
				}
			};
		},
		methods: {
			startLoading() {
				this.isLoading = true;
			},
			endLoading() {
				this.isLoading = false;
			},
			alert(content, color = '') {
				this.snackbar.text = content;
				this.snackbar.enable = true;
				this.snackbar.color = color;
			},
			login() {
				
				this.userName = this.userName.trim();
				
				if (this.userName == '') {
					this.alert('Username cannot be empty');
				}else{

					this.startLoading();

					axios.post(`webapp/user_login`, {
						user_name: this.userName
					}).then((response) => {
						if(!response.data.error && response.data.data){
							AppData.commit('setUser', response.data.data.user);
						}else{
							this.alert(response.data.error);
						}
					}).catch((err) => {
						this.alert(err.message);
					}).finally(() => {
						this.endLoading();
					})
					
				}

			},
		},
	};
</script>