<template>
    <div class="video-card position-relative">
        <v-img :src="video.imageUrl" max-height="35vh" class="rounded-lg" :aspect-ratio="2/3"></v-img>
        <div class="stats position-absolute bottom-0 w-100 mb-1">
            <div class="d-flex flex-row justify-space-around">
                <v-chip color="pink" v-for="(stat,i) in stats" :key="i" small>
                    <v-icon class="mr-1">{{ stat.icon }}</v-icon>
                    <strong>{{ toHumanRead(stat.count) }}</strong>
                </v-chip>
            </div>
        </div>
        
    </div>
</template>

<script>

import { mdiHeart, mdiCommentProcessing, mdiShareCircle, mdiPlayCircle } from '@mdi/js';

export default {
    data() {
        return {
            icons: {
                mdiHeart, mdiCommentProcessing, mdiShareCircle, mdiPlayCircle
            }
        }
    },
    props: ['video'],
    methods: {
        toHumanRead(num){
            return num > 1000 && num % 100 == 0 ? (num / 1000) + "K" : num;
        }
    },
    computed: {
        stats() {
            return [
                { icon: this.icons.mdiHeart, count: this.video.diggCount },
                { icon: this.icons.mdiPlayCircle, count: this.video.playCount },
                { icon: this.icons.mdiCommentProcessing, count: this.video.commentCount },
                { icon: this.icons.mdiShareCircle, count: this.video.shareCount },
            ];
        }
    }
}
</script>