<template>
	<div class="app-container">
		<slot></slot>
	</div>
</template>

<script>
	export default {};
</script>

<style>
	.app-container {
		width: 400px;
		max-width: 85%;
		margin: 20px auto;
	}
</style>