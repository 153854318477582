<template>
    <div class="d-flex flex-column align-items-center">
        <div class="avatar-wrapper position-relative pt-8">
            <v-avatar
                class="profile"
                color="grey"
                size="164"
            >
                <v-img :src="item.avatar" @error="item.avatar=thumbDummy">
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-avatar>
            <!-- <span class="avatar-badge rounded-sm red darken-4 small px-8">{{ badgeText }}</span> -->
        </div>

        <v-card-title>{{ item.user_name }}</v-card-title>

        <v-card-subtitle>{{ itemSubtitle }}</v-card-subtitle>

        <v-btn v-if="! isProcessing && ! isValidated" @click="start" :color="btnColor">
            <v-icon small class="mr-1">{{ btnIcon }}</v-icon>
            <span>{{ btnText }}</span>
            <v-icon
                small
                color="yellow darken-2"
                class="ml-1"
                >$vuetify.icons.coin</v-icon
            >
        </v-btn>

        <div class="d-flex">
            <v-btn 
                v-if="targetUrl && isValidated" 
                color="deep-purple" 
                @click="openURL" 
                :class="[ !isProcessing ? 'pulse-animation' : '','mx-2' ]" 
            >
                <span>Open Tiktok</span>
                <v-icon small class="ml-1" >{{ icons.mdiShareCircle }}</v-icon>
            </v-btn>

            <v-btn 
                v-if="isValidated" 
                :disabled="!isProcessing" 
                @click="earn" 
                color="indigo" 
                :class="[ isProcessing ? 'pulse-animation' : '','mx-2' ]" 
            >
                <span>Earn +1</span>
                <v-icon
                    small
                    color="yellow darken-2"
                    class="ml-1"
                    >$vuetify.icons.coin</v-icon
                >
            </v-btn>
        </div>

    </div>
</template>

<script>
import { mdiAccountMultiplePlus, mdiSkipNext, mdiHeartPlus, mdiShareCircle } from "@mdi/js";
import AvatarDummy from '../AvatarDummy';
import VideoDummy from '../VideoDummy';

import axios from "axios";

export default {
    props: {
        item: {
            type: Object, 
            required: true,
        },
    },
    data() {
        return {
            // icon
			icons: {
				mdiAccountMultiplePlus,
				mdiSkipNext,
				mdiHeartPlus,
                mdiShareCircle
			}, 
			images: {
				avatarDummy: AvatarDummy,
                videoDummy: VideoDummy,
			},
            recordId: null,
            isValidated: false, 
            isProcessing: false,
            targetUrl: null,
        }
    },
    computed: {
        itemSubtitle() {
            return this.isFollowItem()
				? "Follow me to receive super coins!"
				: "Like my video to receive super coins!";
        },
        btnColor() {
            return this.isFollowItem() ? 'blue' : 'pink';
        },
        btnText() {
            return this.isFollowItem() ? 'Follow' : 'Heart';
        },
        btnIcon() {
            return this.isFollowItem() ? this.icons.mdiAccountMultiplePlus : this.icons.mdiHeartPlus;
        },
        thumbDummy() {
            return this.isFollowItem() ? this.images.avatarDummy : this.images.videoDummy;
        },
        badgeText() {
            return this.isFollowItem() ? 'Profile' : 'Video';
        },
    },
    methods: {
		getItemSubtitle() {
			return this.isFollowItem()
				? "Follow me to receive super coins!"
				: "Like my video to receive super coins!";
		},
		isFollowItem() {
			return this.item && !this.item.video_id;
		},
        start() {

            
            this.setLoading(true);

			// reset this.currentItem.recordId
			this.recordId = null;

            let startRequest = this.isFollowItem() ? this.followStart() : this.heartStart();

            startRequest.then((response) => {
                if (!response.data.error) {
                    this.recordId = response.data.data.record_id;
                    this.isValidated = true;

                    if (this.isFollowItem()) {
                        this.targetUrl = `https://www.tiktok.com/@${this.item.user_name}`;
                    } else {
                        this.targetUrl = `https://www.tiktok.com/@${this.item.user_name}/video/${this.item.video_id}`;
                    }
                } else {
                    throw response.data.error;
                }
            }).catch((error) => {
                console.log(error);
                this.alertError("Something went wrong, you cannot earn from this account, please SKIP");
            }).finally(() => {
                this.setLoading(false);
            });
        },
		openURL() {

			/**
			 * try to open modal
			 */
            if(this.targetUrl) {
                window.open(
                    this.targetUrl,
                    `window-tiktok-user-${this.isFollowItem() ? this.user_name : this.video_id}`,
                    "height=710,width=425"
                )
                this.isProcessing = true;
            }

		},
        earn() {
            this.setLoading(true);

            let finish = this.isFollowItem(this.item) ? this.followFinish() : this.heartFinish();

            finish.then((response) => {
                if (!response.data.error) {
                    if (response.data.data.user) {
                        this.setCurrentUser(response.data.data.user);
                        this.$emit('next-item');
                        this.targetUrl = null;
                    }
                } else {
                    throw response.data.error;
                }
            }).catch((error) => {
                this.alertError(error);
            }).finally(() => {
                this.setLoading(false);
            });
        },
		async followStart() {
			return await axios.post(`webapp/user_follow/start`, {
                user_name: this.getCurrentUsername(),
                user_following: this.item.user_name,
            })
		},
		async followFinish() {
			return await axios.post(`webapp/user_follow/finish`, {
                user_name: this.getCurrentUsername(),
                record_id: this.recordId,
            })
		},
		async heartStart() {
			return await axios.post(`webapp/user_like/start`, {
                user_name: this.getCurrentUsername(),
                video_id: this.item.video_id,
            })
		},
		async heartFinish() {
			return await axios.post(`webapp/user_like/finish`, {
                user_name: this.getCurrentUsername(),
                record_id: this.recordId,
            })
		},
        alertError(msg) {
            this.$emit("alert-error", msg);
        },
        setLoading(loading) {
            this.$emit("set-loading", loading);
        },
    },
}
</script>

<style>
.avatar-badge {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    text-transform: uppercase;
}
</style>