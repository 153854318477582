<template>
    <div class="app-download-bar">
        <div class="app-download">
            <a href="https://apps.apple.com/us/app/tikfans-followers-likes/id1559860254" target="_blank" class="download-app-item">
                <v-img src="@/assets/appstore.png"></v-img>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ikong.tikfans" target="_blank" class="download-app-item">
                <v-img src="@/assets/googleplay.png"></v-img>
            </a>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style>
.app-download-bar {
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #404040;
    padding: 10px 0;
}

.app-download {
    width: 84vw;
    display: flex;
    justify-content: space-between;
}

.app-download .download-app-item {
    max-width: 40vw;
}
</style>
