import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

const AppData = new Vuex.Store({
    state: {
        user: null,
        userBalanceChange: 0,
        isUserBalanceChanged: false,
        packages: {
            coins: null,
            followers: null,
            hearts: null,
        },
        queueList: null,
    },
    mutations: {
        setUser(state, data){
            
            /**
             * if there is change in user balance, we should set balance change first 
             * then timeout for updating current user
             */
            if(state.user && state.user.user_coin - data.user_coin != 0) {
                /**
                 * set the balance change first
                 */
                state.userBalanceChange = data.user_coin - state.user.user_coin;
                state.isUserBalanceChanged = true;

                /**
                 * timeout for updating current user
                 */
                setTimeout(() => {
                    state.user = data;
                }, 500);
                
                /**
                 * timeout to remove balance change
                 */
                setTimeout(() => {
                    state.userBalanceChange = 0;
                }, 3000);

                /**
                 * timeout to hide balance change
                 */
                setTimeout(() => {
                    state.isUserBalanceChanged = false;
                }, 1500)
            } else {
                state.user = data;
            }
            
            localStorage.setItem('currentUser', JSON.stringify(data));
        },
        setPackages(state, data) {
            state.packages.coins     = data.purchase_list;
            state.packages.followers = data.product_list.filter((_package) => !_package.hearts);
            state.packages.hearts    = data.product_list.filter((_package) => _package.hearts);
        },
        setQueueList(state, data) {
            state.queueList = data;
        },
        logout(state) {
            state.user = null;
            localStorage.removeItem('currentUser');
        },
    },
    getters: {
        getUser(state) {
            if(!state.user){
                state.user = JSON.parse(localStorage.getItem('currentUser'));
            }
            return state.user;
        },
        getCoinPackages(state) {
            return state.packages.coins;
        },
        getFollowerPackages(state) {
            return state.packages.followers;
        },
        getHeartPackages(state) {
            return state.packages.hearts;
        },
        getQueueList(state) {
            return state.queueList;
        },
        getUserBalanceChange(state) {
            return state.userBalanceChange;
        },
        isUserBalanceChanged(state){
            return state.isUserBalanceChanged;
        },
    },
});

export default AppData;
