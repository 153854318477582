<template>
	<app-wrapper>
		<app-container>
			<!-- listing packages -->
			<package-list>
				<package-item-coin
					v-for="_package in packages"
					:key="_package.id"
					:thePackage="_package"
					:currency="currency"
					@buy-follower-package="purchasePackage"
				></package-item-coin>
			</package-list>

			<!-- Payment sheet -->
			<v-bottom-sheet v-model="sheet" inset>
				<v-sheet class="text-center">
					<v-container>

						<!-- close btn -->
						<v-btn
							class="my-6"
							@click="sheet = !sheet"
						>
							close
						</v-btn>

						<!-- selected package -->
						<package-item-coin
							v-if="order.package"
							:thePackage="order.package"
							:currency="currency"
						></package-item-coin>

						<!-- payment section -->
						<payment-paypal
							class="my-4"
							:order="order"
							@alert-error="
								(msg) => $emit('alert-error', msg)
							"
							@alert-success="
								(msg) => $emit('alert-success', msg)
							"
							@reset-sheet="sheet = false"
							@setLoading="(_isLoading) => isLoading = _isLoading"
						></payment-paypal>

					</v-container>
				</v-sheet>
			</v-bottom-sheet>
		</app-container>

		<v-overlay :value="isLoading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</app-wrapper>
</template>
<script>
/**
 * components
 */
import AppWrapper from "./layouts/AppWrapper.vue";
import AppContainer from "./layouts/AppContainer.vue";
import PackageList from "../components/PackageList.vue";
import PackageItemCoin from "../components/PackageItemCoin.vue";

import PaymentPaypal from "../components/PaymentPaypal.vue";
import AppData from "../AppData";

export default {
	data() {
		return {
			currency: "$",
			sheet: false,
			order: {
				amount: 0,
				package: null,
			},
			isLoading: false,
		};
	},
	methods: {
		purchasePackage(product) {
			this.order.package = product;
			this.order.amount = product.price;
			this.sheet = true;
		},
	},
	computed: {
		packages() {
			let oriRate = 0.05;

			let packages = AppData.getters.getCoinPackages
				.map((product) => {
					product.sale =
						parseInt(
							((oriRate - product.price / product.coin) /
								oriRate) *
								100
						) + "%";
					return product;
				})
				.filter((product) => {
					return product.price > 0;
				});

			return packages;
		},
	},

	components: {
		AppWrapper,
		AppContainer,
		PackageList,
		PackageItemCoin,
		PaymentPaypal,
	},
};
</script>
<style>
.product-sale {
	position: absolute;
	top: 0;
	left: 0;
	padding: 5px 15px;
	border-bottom-right-radius: 8px;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	background: #8a2020;
}
</style>