<template>
	<app-wrapper>
		<app-container>
			<packages-list>
				<package-item
					v-for="_package in packages"
					:key="_package.id"
					:thePackage="_package"
					@buy-follower-package="confirmPurchasePackage"
				>
					<template slot="left">
						<div class="package-price d-flex align-items-center">
							<v-icon color="yellow darken-2" x-small class="mr-2">{{ icons.mdiMinus }}</v-icon>

							<v-icon color="yellow darken-2" small>$vuetify.icons.coin</v-icon>
							<span class="price h5 font-weight-bold mb-0 ml-1">{{
								_package.coins
							}}</span>
						</div>
					</template>

					<template slot="right">
						<div class="package-coins d-flex align-items-center">
							<v-icon color="yellow darken-2">{{
								icons.mdiAccountMultiplePlus
							}}</v-icon>

							<span class="h4 font-weight-bold ml-1 mb-0">{{
								_package.followers
							}}</span>
						</div>
					</template>
				</package-item>
			</packages-list>
		</app-container>
		
		<!-- Confirm sheet -->
		<v-bottom-sheet v-model="sheet" inset>
			<v-sheet class="text-center">
				<v-container>

					<!-- close btn -->
					<v-btn
						class="m-6 mx-3"
						@click="sheet = !sheet"
					>
						close
					</v-btn>

					<v-btn
						class="my-6 mx-3"
						@click="purchaseSelectedPackage"
						color="pink darken-1"
					>
						confirm
					</v-btn>

					<!-- selected package -->
					<packages-list>
						<package-item v-if="selectedPackage" :thePackage="selectedPackage" >
							<template slot="left">
								<div class="package-price d-flex align-items-center">
									<v-icon color="yellow darken-2" x-small class="mr-2">{{ icons.mdiMinus }}</v-icon>

									<v-icon color="yellow darken-2" small>$vuetify.icons.coin</v-icon>
									<span class="price h5 font-weight-bold mb-0 ml-1">{{
										selectedPackage.coins
									}}</span>
								</div>
							</template>

							<template slot="right">
								<div class="package-coins d-flex align-items-center">
									<v-icon color="yellow darken-2">{{
										icons.mdiAccountMultiplePlus
									}}</v-icon>

									<span class="h4 font-weight-bold ml-1 mb-0">{{
										selectedPackage.followers
									}}</span>
								</div>
							</template>
						</package-item>
					</packages-list>

				</v-container>
			</v-sheet>
		</v-bottom-sheet>
		
		<v-overlay :value="isLoading || !packages || !packages.length">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</app-wrapper>
</template>

<script>
import AppWrapper from "./layouts/AppWrapper.vue";
import AppContainer from "./layouts/AppContainer.vue";
import PackagesList from "../components/PackageList.vue";
import PackageItem from "../components/PackageItem.vue";

import axios from "axios";

import { mdiAccountMultiplePlus, mdiMinus } from "@mdi/js";
import AppData from "../AppData";

export default {
	data() {
		return {
			isLoading: false,
			sheet: false,
			icons: {
				mdiAccountMultiplePlus, mdiMinus
			},
			selectedPackage: null,
		};
	},
	methods: {
		alertError(message) {
			this.$emit("alert-error", message);
		},
		alertSuccess(message) {
			this.$emit("alert-success", message);
		},
		purchasePackage(_package) {
			if (this.currentUser.user_coin < _package.coins) {
				this.$emit('alert-insufficient');
			} else {
				this.isLoading = true;

				axios.post('webapp/buy_followers', {
					user_name: this.currentUser.user_name,
					package_id: _package.id,
				}).then((response) => {
					if(!response.data.error && response.data.data.user){
						this.setCurrentUser(response.data.data.user);
						this.alertSuccess('Purchase successfully!');
					}else{
						this.alertError(response.data.error);
					}
				}).catch((err) => {
					this.alertError(err.message);
				}).finally(() => {
					this.isLoading = false;
				});
			}
		},
		purchaseSelectedPackage() {
			this.sheet = false;
			this.purchasePackage(this.selectedPackage);
		},
		confirmPurchasePackage(_package) {
			if (this.currentUser.user_coin < _package.coins) {
				this.$emit('alert-insufficient');
			}else{
				this.selectedPackage = _package;
				this.sheet = true;
			}
		}
	},
	computed: {
		packages() {
			return AppData.getters.getFollowerPackages;
		},
	},
	components: { AppWrapper, AppContainer, PackagesList, PackageItem },
};
</script>