<template>
	<v-app>
		<v-app-bar app>
			<div
				class="w-100 d-flex justify-content-between align-items-center"
			>
				<div class="left">
					<div
						class="
							top-bar-user-balance
							d-flex
							align-items-center
							position-relative
						"
					>
						<v-btn rounded small color="grey darken-2">
							<v-icon color="yellow darken-2" small left
								>$vuetify.icons.coin</v-icon
							>
							<strong>{{ currentUser.user_coin }}</strong>
						</v-btn>

						<transition name="slideInAndOut">
							<div
								v-show="isUserBalanceChanged"
								class="balance-adjustment"
							>
								<v-chip color="transparent">
									{{
										userBalanceChange > 0
											? `+${userBalanceChange}`
											: userBalanceChange
									}}
									<v-icon color="yellow darken-2" small right
										>$vuetify.icons.coin</v-icon
									>
								</v-chip>
							</div>
						</transition>
					</div>
				</div>
				<v-btn
					rounded
					small
					class="pr-0 pl-0 text-lowercase"
					color="grey darken-2"
				>
					<strong>
						<icon-tiktok></icon-tiktok>
						{{ currentUser.user_name }}
					</strong>
					<v-avatar color="grey lighten-1" class="ml-2">
						<v-img
							:src="currentUser.avatar"
							:lazy-src="currentUser.avatar"
						></v-img>
					</v-avatar>
				</v-btn>
			</div>
		</v-app-bar>

		<v-main app class="fill-height">
			<keep-alive>
				<component
					:is="currentScreenComponent"
					@alert="alert"
					@alert-error="alertError"
					@alert-success="alertSuccess"
					@alert-insufficient="alertInsufficient"
				></component>
			</keep-alive>

			<v-dialog v-model="dialog.enabled" max-width="400px">
				<v-alert
					class="app-dialog-alert"
					dark
					border="top"
					prominent
					:type="dialog.type"
				>
					<div class="d-flex flex-column align-items-center">
						<p class="text-center">{{ dialog.content }}</p>

						<div class="btns">
							<v-btn
								@click="closeDialog"
								outlined
								class="mx-3"
							>
								Ok
							</v-btn>

							<v-btn
								@click="closeDialog(); goScreen('buy_coins');"
								v-if="dialog.showBuyCoinsBtn"
								outlined
								class="mx-3"
							>
								<v-icon color="yellow darken-2" small left
									>$vuetify.icons.coin</v-icon
								>
								<strong>Buy Coins</strong>
							</v-btn>
						</div>
					</div>
				</v-alert>
			</v-dialog>

			<app-download-bar></app-download-bar>
		</v-main>

		<v-bottom-navigation
			color="teal"
			fixed
			grow
			v-model="defaultScreen"
			app
		>
			<v-btn
				v-for="(screen, index) in screens"
				:key="index"
				@click="setCurrentScreen(screen)"
				:value="index"
			>
				<v-icon>{{ screen.icon }}</v-icon>
			</v-btn>
		</v-bottom-navigation>
	</v-app>
</template>

<script>
import ScreenList from "./screens/List";
import ScreenBuyCoins from "./screens/BuyCoins";
import ScreenBuyFollowers from "./screens/BuyFollowers";
import ScreenAccount from "./screens/Account.vue";
import ScreenBuyHearts from "./screens/BuyHearts.vue";
import IconTiktok from "./components/IconTiktok.vue";
import AppDownloadBar from "./components/AppDownloadBar.vue";

import {
	mdiHome,
	mdiCreditCard,
	mdiHeartPlus,
	mdiAccountMultiplePlus,
	mdiAccountCircle,
} from "@mdi/js";
import AppData from "./AppData";

export default {
	data() {
		return {
			defaultScreen: "home",
			currentScreen: null,
			screens: {
				home: {
					title: "Home",
					icon: mdiHome,
					component: "ScreenList",
				},
				buy_followers: {
					title: "Buy Followers",
					icon: mdiAccountMultiplePlus,
					component: "ScreenBuyFollowers",
				},
				buy_hearts: {
					title: "Buy Hearts",
					icon: mdiHeartPlus,
					component: "ScreenBuyHearts",
				},
				buy_coins: {
					title: "Buy Coins",
					icon: mdiCreditCard,
					component: "ScreenBuyCoins",
				},
				account: {
					title: "Account",
					icon: mdiAccountCircle,
					component: "ScreenAccount",
				},
			},
			dialog: {
				enabled: false,
				content: "",
				showBuyCoinsBtn: false,
			},
		};
	},
	components: {
		ScreenList,
		ScreenBuyCoins,
		ScreenBuyFollowers,
		ScreenAccount,
		ScreenBuyHearts,
		IconTiktok,
		AppDownloadBar,
	},
	computed: {
		currentScreenComponent() {
			return this.currentScreen.component;
		},
		userBalanceChange() {
			return AppData.getters.getUserBalanceChange;
		},
		isUserBalanceChanged() {
			return AppData.getters.isUserBalanceChanged;
		},
	},
	methods: {
		goScreen(screenId) {
			this.defaultScreen = screenId;
			this.setCurrentScreen(this.getScreen(screenId));
		},
		getScreen(screenId) {
			return this.screens[screenId];
		},
		setCurrentScreen(screen) {
			this.currentScreen = screen;
		},
		alert(content, type = "warning") {
			this.dialog.enabled = true;
			this.dialog.content = content;
			this.dialog.type = type;
		},
		alertError(content) {
			this.alert(content, "error");
		},
		alertSuccess(content) {
			this.alert(content, "success");
		},
		alertInsufficient() {
			this.dialog.showBuyCoinsBtn = true;
			this.alertError("Oops! You don't have enough coins to purchase this package");
		},
		closeDialog() {
			this.dialog = {
				enabled: false,
				content: "",
				showBuyCoinsBtn: false,
			};
		}
	},
	created() {
		this.currentScreen = this.getScreen(this.defaultScreen);
	},
};
</script>

<style>
.balance-adjustment {
	position: absolute;
	right: 0;
	transform: translate(100%, 0);
	transition: top 0.3s, opacity 0.2s;
}

.slideInAndOut-enter {
	top: 1.2em;
	opacity: 0;
}

.slideInAndOut-leave-to {
	top: -0.2em;
	opacity: 0;
}

.slideInAndOut-enter-to,
.slideInAndOut-leave {
	opacity: 1;
	top: 0;
}
</style>