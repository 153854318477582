<template>
	<AppUser v-if="currentUser"></AppUser>
	<AppLogin v-else></AppLogin>
</template>

<script>
import AppUser from "./AppUser.vue";
import AppLogin from "./AppLogin.vue";

export default {
	components: { 
		AppUser, 
		AppLogin 
	},
};
</script>

<style>
</style>