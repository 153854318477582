import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import AppData from './AppData';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/css/main.scss";

Vue.config.productionTip = false

const axios = require('axios').default;

axios.defaults.baseURL = "https://tiktok.apprankings.net/";
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.responseType = 'json';

const VueMixin = {
	methods: {
		getCurrentUser() {
			return AppData.getters.getUser;
		},
		setCurrentUser(user) {
			AppData.commit('setUser', user);
		},
		getCurrentUsername() {
			return this.currentUser.user_name;
		},
		refreshCurrentUser() {
			axios.post(`webapp/user_login`, {
				user_name: AppData.getters.getUser.user_name
			}).then((response) => {
				if(!response.data.error && response.data.data){
					console.log('update user');
					AppData.commit('setUser', response.data.data.user);
				}
			}).catch((err) => {
				console.log('failed to update user', err);
			})
		},
	},
	computed: {
		currentUser() {
			return this.getCurrentUser();
		},
	}
};

Vue.mixin(VueMixin);

setInterval(() => {
	if (AppData.getters.getUser) {
		VueMixin.methods.refreshCurrentUser();
	}
}, 1000 * 60 * 5); // 5 mins

Vue.prototype.$theApp = new Vue({
	vuetify,
	render(h) {
		return h(App);
	},
	created() {
		
		/**
		 * App Booting
		 */
		axios
		.get("v4/list_products", {
			params: {
				platform: "webapp",
			},
		})
		.then((response) => {
			if(!response.data.error) {
				AppData.commit('setPackages', response.data.data);
			}
		});

		/**
		 * If user is logged in, then fetch latest info
		 */
		if (this.currentUser) {
			this.refreshCurrentUser();
		}

	}
}).$mount('#app')
