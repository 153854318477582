<template>
    <v-card 
        class="package-card rounded-lg mb-6 pa-6"
        hover
        @click="$emit('buy-follower-package', thePackage)"
    >
        <div
            class="
                d-flex
                justify-content-between
                align-items-center
            "
        >
            <slot name="left"></slot>
            <slot name="right"></slot>
        </div>
    </v-card>
</template>

<script>
	export default {
        props: {
            thePackage: {
                type: Object, 
                required: true,
            },
        }
    };
</script>

<style>
	.package-card {
		overflow: hidden;
	}
</style>