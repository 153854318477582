<template>
    <packages-list>
        <package-item v-for="_package in packages" :key="_package.id" :thePackage="_package" @buy-follower-package="purchasePackage">
            <template slot="left">
                <div class="package-price d-flex align-items-center">
                    <v-icon
                        color="yellow darken-2"
                        x-small
                        class="mr-2"
                    >{{ icons.mdiMinus }}
                    </v-icon>

                    <v-icon
                        color="yellow darken-2"
                        small
                        >$vuetify.icons.coin</v-icon
                    >
                    <span class="price h5 font-weight-bold mb-0 ml-1">{{
                        _package.coins
                    }}</span>
                </div>
            </template>

            <template slot="right">
                <div class="package-coins d-flex align-items-center">
                    <v-icon
                        color="yellow darken-2"
                        >{{ icons.mdiHeartPlus }}</v-icon
                    >

                    <span class="h4 font-weight-bold ml-1 mb-0">{{
                        _package.hearts
                    }}</span>
                </div>
            </template>
        </package-item>
    </packages-list>
</template>

<script>
    
    import PackagesList from '../components/PackageList.vue';
    import PackageItem from '../components/PackageItem.vue';

    import { mdiHeartPlus, mdiMinus } from '@mdi/js';

	export default {
		props: [ 'packages' ],
		data() {
			return {
				icons: {
					mdiHeartPlus, mdiMinus
				},
			};
		},
		methods: {
			purchasePackage(_package) {
                
                if( this.currentUser.user_coin < _package.coins ){
					this.$emit('alert-error', 'Oops! You don\'t have enough coins to purchase this package');
				}else{
					
                    this.$emit('select-package', _package);

					// axios.post('v5/buy_followers', {
					// 	"user_name": this.currentUser.user_name,
					// 	"package_id": _package.id
					// }).then((response) => {
					// 	if(!response.data.error){
					// 		if(response.data.user){
					// 			this.setCurrentUser(response.data.user);
					// 		}
					// 	}else{
					// 		this.$emit('alert-error', response.data.error)
					// 	}
					// }).catch((err) => {
					// 	this.$emit('alert-error', err);
					// }).finally(() => {
					// 	this.$emit('disable-loading');
					// });
				}
            },
		},
        components: { PackagesList, PackageItem }
	};
</script>