<template>
	<package-item
		@buy-follower-package="
			(_package) => $emit('buy-follower-package', _package)
		"
		:thePackage="thePackage"
	>
		<template v-slot:left>
			<div class="product-sale text-white">
				<span class="percentage">{{ thePackage.sale }}</span>
				off
			</div>

			<div class="product-price mt-5">
				<span class="currency">{{ currency }}</span>
				<span class="price h5 font-weight-bold">{{
					thePackage.price
				}}</span>
			</div>
		</template>

		<template v-slot:right>
			<div class="product-coins">
				<v-icon color="yellow darken-2" small class="align-baseline"
					>$vuetify.icons.coin</v-icon
				>
				<span class="h4 font-weight-bold ml-1">{{
					thePackage.coin
				}}</span>
			</div>
		</template>
	</package-item>
</template>

<script>
import PackageItem from "./PackageItem.vue";

export default {
	props: {
		thePackage: {
			type: Object,
			required: true,
		},
		currency: {
			type: String,
			required: true,
		},
	},
	components: { PackageItem },
};
</script>