<template>
	<app-wrapper>
		<app-container>
			<v-card
				class="pb-8 d-flex flex-column align-items-center"
				:loading="isLoading"
				v-if="items.length"
			>
				<v-overlay :value="isLoading" absolute></v-overlay>

				<v-carousel
					v-model="currentItem.index"
					height="350px"
					:continuous="false"
					hide-delimiters
					:show-arrows="false"
					touchless
				>
					<v-carousel-item v-for="(item, idx) in items" :key="idx">
						<item-exchange 
							:item="item" 
							@alert="(message) => $emit('alert', message)"
							@alert-error="(message) => $emit('alert-error', message)"
							@alert-success="(message) => $emit('alert-success', message)"
							@set-loading="(loading) => isLoading = loading"
							@next-item="nextItem()"
						></item-exchange>
					</v-carousel-item>
				</v-carousel>

				<v-card-actions>
					<v-btn @click="skip">
						<v-icon small class="mr-1">{{
							icons.mdiSkipNext
						}}</v-icon>
						<span>Skip</span>
					</v-btn>
					<v-btn @click="shuffle">
						<v-icon small class="mr-1">{{ icons.mdiShuffleVariant }}</v-icon>
						<span>Shuffle</span>
					</v-btn>
				</v-card-actions>
			</v-card>

			<v-skeleton-loader v-else type="image"></v-skeleton-loader>
		</app-container>
	</app-wrapper>
</template>

<script>
import AppWrapper from "./layouts/AppWrapper.vue";
import AppContainer from "./layouts/AppContainer.vue";
import ItemExchange from '../components/ItemExchange.vue';

import { mdiAccountMultiplePlus, mdiSkipNext, mdiHeartPlus, mdiShuffleVariant } from "@mdi/js";

import axios from "axios";

import AppData from "../AppData";

export default {
	data() {
		return {
			// icon
			icons: {
				mdiAccountMultiplePlus,
				mdiSkipNext,
				mdiHeartPlus,
				mdiShuffleVariant
			},
			currentItem: {
				index: 0,
				
			},
			items: [],
			isLoading: false,
		};
	},
	components: { AppWrapper, AppContainer, ItemExchange },
	methods: {
		getCurrentItem() {
			return this.items[this.currentItem.index];
		},
		nextItem() {
			this.currentItem.index++;
			this.isLoading = false;
		},
		skip() {
			this.nextItem();
		},
		shuffle() {
			this.refreshList();
		},
		refreshList() {
			this.currentItem.index = -1;
			this.items = [];

			axios
				.get(
					`v3/list_need_follow?user_name=${this.currentUser.user_name}`
				)
				.then((response) => {
					this.items = response.data.data.list;
				})
				.catch((error) => {
					this.$emit("alert", error);
				})
				.finally(() => {
					this.currentItem.index = 0;
				});
		},
	},
	created() {
		if (!(this.items = AppData.getters.getQueueList)) {
			this.refreshList();
		}
	},
};
</script>
<style lang="">
</style>